import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import PopOutModal from '@/components/molecules/legacy/PopOutModal/PopOutModal';
import { DEFAULT_US_ZIP_CODE } from '@/lib/constants';
import { JDPowerIncentive } from '@/lib/schema/incentives/types';
import { MapPinIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useRouter } from 'next/router';
import IncentivesCard from '../IncentivesCard/IncentivesCard';

export interface IncentivesOverlayProps {
  className?: string;
  close: () => void;
  incentives: JDPowerIncentive[];
  incentiveAmount: number;
  open: boolean;
}

const IncentivesOverlay: React.FC<IncentivesOverlayProps> = ({
  className,
  close,
  incentives,
  incentiveAmount,
  open,
}) => {
  const router = useRouter();
  const location = router.query.location ?? DEFAULT_US_ZIP_CODE;

  return (
    <PopOutModal
      open={open}
      onClose={close}
      className={`h-full w-full overflow-hidden !bg-white !px-[16px] !pb-[10px] m:h-[95%] l:!px-[32px] l:!pb-[20px] ${className}`}
      otherModalProps={{ 'data-testid': 'incentives-overlay' }}
    >
      <div className="no-scrollbar relative flex max-h-[90vh] flex-col items-start gap-5 self-stretch overflow-y-auto">
        <div className="no-scrollbar relative flex max-h-[90vh] flex-col items-start gap-5 self-stretch overflow-y-auto">
          <Heading
            className="self-stretch text-headline3"
            level={HeadingLevels.H3}
          >
            Estimated Incentives
          </Heading>

          <div className="flex flex-row gap-1">
            <MapPinIcon className="left-0 top-0 pt-1" width={20} height={20} />
            <Heading className="text-body2" level={HeadingLevels.H4}>
              {location}
            </Heading>
          </div>

          <div className="flex flex-col items-start gap-2 self-stretch">
            {incentives.map((incentive) => (
              <div
                className="flex items-start justify-between self-stretch"
                key={incentive.name}
              >
                <p className="text-body3">{incentive.name}</p>
                <p className="text-body3">
                  ${(incentive.max_amount || 0).toLocaleString()}
                </p>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-start self-stretch border-t pt-5">
            <div className="flex items-start justify-between self-stretch">
              <p className="text-body3 font-medium">Total Incentive</p>
              <p className="text-body3 font-medium">
                ${incentiveAmount.toLocaleString()}
              </p>
            </div>
          </div>

          <Heading
            level={HeadingLevels.H4}
            className="mt-3 self-stretch text-subHeadline3"
          >
            Eligible Incentives
          </Heading>
          {incentives &&
            incentives.map((incentive) => (
              <IncentivesCard key={incentive.name} incentive={incentive} />
            ))}

          <Heading
            level={HeadingLevels.H4}
            className="self-stretch text-body4 text-[#636363]"
          >
            Electric vehicles are eligible for various financial incentives and
            other owner benefits that are unavailable to gas cars. Learn more
            about state and utility provider incentives in your area.
          </Heading>
        </div>
      </div>
    </PopOutModal>
  );
};

export default IncentivesOverlay;
