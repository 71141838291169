import {
  calculatedRebateIncentives,
  getGroupedIncentives,
} from '@/lib/incentiveUtils';
import {
  JDPowerIncentive,
  VehicleIncentivesData,
} from '@/lib/schema/incentives/types';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

export const useFetchIncentives = ({
  vin,
  styleId,
  zip,
  zipLoading,
  displayIncentives = true,
}: {
  vin?: string;
  styleId?: number;
  zip: string;
  zipLoading: boolean;
  displayIncentives?: boolean;
}) => {
  const [selectedIncentives, setSelectedIncentives] = useState<
    JDPowerIncentive[]
  >([]);

  const fetchIncentives = async () => {
    if (zipLoading) {
      return undefined;
    }
    const styleIDOrVin = vin || styleId;
    if (!styleIDOrVin) {
      return undefined;
    }

    const response = await fetch(
      `/api/incentives/get_ev_incentives/${styleIDOrVin}/${zip}`
    );
    if (!response.ok) {
      throw new Error('Failed to fetch incentives');
    }
    const incentivesData = (await response.json()) as VehicleIncentivesData;

    setSelectedIncentives(
      incentivesData.all.filter((i) => !!i.max_amount) ?? []
    );

    return incentivesData;
  };

  const {
    data: incentivesData,
    isFetching: incentivesLoading,
    isError: incentivesFailed,
  } = useQuery({
    queryKey: ['incentives', vin, styleId, zip, zipLoading],
    queryFn: fetchIncentives,
    refetchOnWindowFocus: false,
    throwOnError: false,
  });

  const selectedIncentiveTotals = useMemo(() => {
    if (selectedIncentives) {
      return calculatedRebateIncentives(
        selectedIncentives,
        getGroupedIncentives(selectedIncentives)
      );
    }
    return {
      rebate: 0,
      incentives: 0,
    };
  }, [selectedIncentives]);

  return {
    incentivesData,
    selectedIncentives: displayIncentives ? selectedIncentives : [],
    setSelectedIncentives,
    selectedIncentiveTotals,
    incentivesLoading,
    incentivesFailed,
  };
};
