import { parseRangeWithUnit } from '@/lib/parseRangeWithUnit';
import {
  ColorObject,
  CustomTrimDetail,
  ExtendedOptionsFields,
  ImageObject,
  RawTrimDetails,
  TrimStyle,
} from '@/lib/schema/build/types';

export const getDefaultImage = (images: ImageObject[]): ImageObject => {
  const image = images
    .filter((x) => x.images.length > 0 && x.msrp === 0) // default image should have at least one image
    .sort((x) => x.images[0]?.width)[0];
  return {
    ...image,
    images: image?.images || [],
    colorName: 'No Preference',
  };
};
export const FallBackColor: ColorObject = {
  colorName: 'No Preference',
  msrp: 0,
  rgb: null,
};
export const recoverySelections = ({
  trimName,
  exteriorName,
  interiorColorName,
  trims,
  extendOptionDescriptions: descriptionsFromUrl,
}: {
  trims: TrimStyle[];
  trimName: string;
  exteriorName?: string;
  interiorColorName?: string;
  extendOptionDescriptions?: string[] | string;
}): CustomTrimDetail | null => {
  const trim = trims.find((t) => t.trim === trimName);

  if (!trim) {
    return null;
  }

  const optionDescriptions = Array.isArray(descriptionsFromUrl)
    ? descriptionsFromUrl
    : [descriptionsFromUrl];

  return {
    trim,
    exteriorColor:
      trim.exteriorImages.find((x) => x.colorName === exteriorName) ||
      getDefaultImage(trim.exteriorImages),
    interiorColor:
      trim.interiorColors.find((x) => x.colorName === interiorColorName) ||
      FallBackColor,
    extendedOptions: trim.extendedOptions
      ? Object.values(trim.extendedOptions)
          .flat()
          .filter(
            (option) =>
              option.mandatory ||
              optionDescriptions.includes(option.description)
          )
      : [],
  };
};

const removeTags = (str: string) => {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
};
const parseExtendedOptions = (
  option: ExtendedOptionsFields
): ExtendedOptionsFields => {
  const { description } = option;
  return {
    ...option,
    description: removeTags(description),
  };
};

const parseExtendedOptionsObject = (
  obj: RawTrimDetails['ExtendedOptions'] = {}
): RawTrimDetails['ExtendedOptions'] => {
  return Object.entries(obj).reduce(
    (acc: RawTrimDetails['ExtendedOptions'], [key, value]) => {
      if (acc) {
        acc[key] = value.map(parseExtendedOptions);
      }
      return acc;
    },
    {} as RawTrimDetails['ExtendedOptions']
  );
};

export const parseBuildTrim = (style: RawTrimDetails): TrimStyle => {
  return {
    trimId: style._id,
    year: style.Year,
    make: style.Make,
    model: style.Model,
    modelId: style.ModelId || '',
    trim: style.Trim,
    fuelType: style.FuelType || '',
    rangeAllElectric:
      (style.RangeAllElectric &&
        parseRangeWithUnit({
          value: style.RangeAllElectric.Value,
          unit: style.RangeAllElectric.Unit,
        })) ||
      null,
    rangeHybrid:
      (style.RangeHybrid &&
        parseRangeWithUnit({
          value: style.RangeHybrid.Value,
          unit: style.RangeHybrid.Unit,
        })) ||
      (style.RangeHybridElectric &&
        parseRangeWithUnit({
          value: style.RangeHybridElectric.Value,
          unit: style.RangeHybridElectric.Unit,
        })) ||
      null,
    msrp: style.MSRP,
    range:
      (style.Range &&
        parseRangeWithUnit({
          value: style.Range.Value,
          unit: style.Range.Unit,
        })) ||
      null,
    horsepower: style['HorsePower@RPM'] || '',
    exteriorImages: Object.entries(style.ExteriorImages || {})
      .map(([code, { RGB, ColorName = '', MSRP = 0, Images = [] }]) => {
        return {
          code,
          rgb: RGB,
          colorName: ColorName,
          msrp: MSRP,
          images: Images.map((image) => ({
            href: image.href,
            shotCode: image.ShotCode || '',
            shotDest: image.ShotDest || '',
            background: image.Background,
            width: image.Width || 0,
            height: image.Height || 0,
          })),
        };
      })
      .sort((a, b) => a.msrp - b.msrp),
    interiorImages: Array.isArray(style.InteriorImages)
      ? style.InteriorImages?.map((image) => ({
          href: image.href,
          shotCode: image.ShotCode,
          shotDest: image.ShotDest || '',
          background: image.Background,
          // currently not storing dimensions of interior images in backend, only exterior images
          width: image.Width || 0,
          height: image.Height || 0,
        }))
      : [],
    interiorColors:
      style.InteriorColors?.map((color) => ({
        colorName: color.ColorName,
        msrp: color.MSRP,
        rgb: color.RGB,
      })) || [],
    extendedOptions: parseExtendedOptionsObject(style.ExtendedOptions || {}),
    incentivesTotal: style?.IncentivesTotal ?? null,
    incentives: style?.Incentives ?? [],
    federalTaxCredit: style?.FederalTaxCredit ?? 0,
    drivetrain: style?.Drivetrain ?? '',
  };
};

export const getRange = (trim: TrimStyle) => {
  if (trim.rangeAllElectric) {
    const { rangeAllElectric } = trim;
    rangeAllElectric.value = Math.round(rangeAllElectric.value);
    return rangeAllElectric;
  }
  if (trim.rangeHybrid) {
    const { rangeHybrid } = trim;
    rangeHybrid.value = Math.round(rangeHybrid.value);
    return rangeHybrid;
  }
  return null;
};
export default parseBuildTrim;
